import clsx from 'clsx'
import styles from './Admin.module.scss'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Login from './Login'
import useToken from './useToken'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getProjects, updateProjects } from '~/services/projects'
import { Dialog, Toast } from '~/utils/Noti'

function Projects() {
    document.title = titles.adminProjects
	useEffect(() => window.scrollTo(0, 0), [])

	const { token, setToken } = useToken()
	const [projects, setProjects] = useState([])
	const [editingProject, setEditingProject] = useState(null)
	const [yearWarning, setYearWarning] = useState(null)
	const [titleWarning, setTitleWarning] = useState(null)
	const [descriptionWarning, setDescriptionWarning] = useState(null)
	const yearRef = useRef(null)
	const titleRef = useRef(null)
	const descriptionRef = useRef(null)
	const sourceUrlRef = useRef(null)
	const demoUrlRef = useRef(null)
	const mounted = useRef(true)

	useEffect(() => {
		mounted.current = true
		getProjects()
		.then(items => {
			if(mounted.current) setProjects(items.data)
		})
		return () => mounted.current = false
	}, [])

	const handleSubmit = async e => {
		e.preventDefault()
		if (yearRef.current.value.trim() === '') {
			setYearWarning('Year complete can\'t be blank.')
			return
		}
		if (titleRef.current.value.trim() === '') {
			setTitleWarning('Title can\'t be blank.')
			return
		}
		if (descriptionRef.current.value.trim() === '') {
			setDescriptionWarning('Description can\'t be blank.')
			return
		}
		const newProject = {
			year_complete: yearRef.current.value.trim(),
			title: titleRef.current.value.trim(),
			description: descriptionRef.current.value.trim(),
			source_url: sourceUrlRef.current.value.trim(),
			demo_url: demoUrlRef.current.value.trim()
		}
		if (editingProject) {
			const response = await updateProjects({ ...newProject, id: editingProject.id }, 'PUT')
			if (response.code === '10') {
				Toast('success', 'Update project successfully.', newProject.title)
				setEditingProject(null)
			}
		} else {
			const response = await updateProjects(newProject, 'POST')
			if (response.code === '8') {
				Toast('success', 'Create new project successfully.', newProject.title)
			}
		}
		if (mounted.current) {
			yearRef.current.value = ''
			titleRef.current.value = ''
			descriptionRef.current.value = ''
			sourceUrlRef.current.value = ''
			demoUrlRef.current.value = ''
		}
		getProjects().then(items => setProjects(items.data))
	}

	const handleEdit = project => {
		setEditingProject(project)
		yearRef.current.value = project.year_complete
		titleRef.current.value = project.title
		descriptionRef.current.value = project.description
		sourceUrlRef.current.value = project.source_url
		demoUrlRef.current.value = project.demo_url
		window.scrollTo(0, 0)
	}

	const handleDelete = async projectId => {
		const resConfirmed = await Dialog('Confirm deletion', 'Are you sure you want to delete this project?')
		if (resConfirmed) {
			const response = await updateProjects({id: projectId}, 'DELETE')
			if (response.code === '7') {
				Toast('success', 'Delete project successfully.')
				getProjects().then(items => setProjects(items.data))
			}
		}
	}
	
	if (!token) return <Login setToken={setToken}/>
	return (
		<div className={clsx(styles.wrapper, 'container')}>
			<h1>Projects</h1>
			<form className={clsx(styles.form)} onSubmit={handleSubmit}>
				<div className={clsx(styles.field)}>
					<label htmlFor='year'>Year complete</label>
					<input id='year' type='text' placeholder='2024' ref={yearRef}
						onChange={() => setYearWarning(null)}
					/>
					<p className={yearWarning && styles.warning}>{yearWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='title'>Title</label>
					<input id='title' type='text' placeholder='TakeNote' ref={titleRef}
						onChange={() => setTitleWarning(null)}
					/>
					<p className={titleWarning && styles.warning}>{titleWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='description'>Description</label>
					<input id='description' type='text' placeholder='A free, open source notes app for the web.' ref={descriptionRef}
						onChange={() => setDescriptionWarning(null)}
					/>
					<p className={descriptionWarning && styles.warning}>{descriptionWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='source_url'>Source url</label>
					<input id='source_url' type='text' placeholder='https://source_url.com' ref={sourceUrlRef}/>
					<p>Some additional Info</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='demo_url'>Demo url</label>
					<input id='demo_url' type='text' placeholder='https://demo_url.com' ref={demoUrlRef}/>
					<p>Some additional Info</p>
				</div>
				<button type="submit" className={clsx(styles.submit, 'btn active')}>
					{editingProject ? 'Update' : 'Create'}
				</button>
			</form>
			<div className={clsx(styles.data)}>
				<h2><Link to={routes.projects}>All Projects</Link></h2>
				<div className={clsx(styles.tableWrapper)}>
					<table>
						<thead>
							<tr>
								<th>Year complete</th>
								<th>Title</th>
								<th>Description</th>
								<th>Source url</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{projects && projects.map(project => (
								<tr key={project.id}>
									<td>{project.year_complete}</td>
									<td className={clsx(styles.writingTitle)}><Link to={project.demo_url} target='_blank'>{project.title}</Link></td>
									<td>{project.description}</td>
									<td className={clsx(styles.writingTags)}>
										{project.source_url ? <Link to={project.source_url} target='_blank'>{project.source_url}</Link> : '-'}
									</td>
									<td className={clsx(styles.action)}>
										<Link onClick={() => handleEdit(project)}><FontAwesomeIcon icon={faPen}/></Link>
										<Link onClick={() => handleDelete(project.id)}><FontAwesomeIcon icon={faTrash}/></Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export { Projects }