import config from '~/config'
import Home from '~/pages/Home'
import About from '~/pages/About'
import { Writing, WritingDetails } from '~/pages/Writing'
import { Tags, TagsDetails } from '~/pages/Tags'
import Projects from '~/pages/Projects'
import Quotes from '~/pages/Quotes'
import Payment from '~/pages/Payment'
import {
    Dashboard,
    AdminWriting,
    AdminTags,
    AdminProjects,
    AdminQuotes,
    AdminPayment 
} from '~/pages/Admin'

const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.about, component: About },
    { path: config.routes.writing, component: Writing },
    { path: config.routes.writingDetails, component: WritingDetails },
    { path: config.routes.projects, component: Projects },
    { path: config.routes.quotes, component: Quotes },
    { path: config.routes.payment, component: Payment },
    { path: config.routes.tags, component: Tags },
    { path: config.routes.tagsDetails, component: TagsDetails },
]

const privateRoutes = [
    { path: config.routes.dashboard, component: Dashboard },
    { path: config.routes.adminWriting, component: AdminWriting },
    { path: config.routes.adminTags, component: AdminTags },
    { path: config.routes.adminProjects, component: AdminProjects },
    { path: config.routes.adminQuotes, component: AdminQuotes },
    { path: config.routes.adminPayment, component: AdminPayment }
]

export { publicRoutes, privateRoutes }