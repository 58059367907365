import clsx from 'clsx'
import styles from './Admin.module.scss'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Login from './Login'
import useToken from './useToken'
import titles from '~/config/titles'
import infos from '~/config/infos'
import routes from '~/config/routes'
import { Editor } from '@tinymce/tinymce-react'
import { useTheme } from '~/layouts/components/ThemeContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import TagsPicker from '~/components/TagsPicker'
import { getWritings, updateWritings } from '~/services/writings'
import { updateWritingTags } from '~/services/writing_tags'
import { Dialog, Toast } from '~/utils/Noti'

function Writing() {
	document.title = titles.adminWriting
	useEffect(() => window.scrollTo(0, 0), [])

	const { token, setToken } = useToken()
	const { theme } = useTheme()
	const [writings, setWritings] = useState([])
	const [editingWriting, setEditingWriting] = useState(null)
	const [tags, setTags] = useState([])
	const [editingTags, setEditingTags] = useState(null)
	const [shouldClearTags, setShouldClearTags] = useState(false)
	const [titleWarning, setTitleWarning] = useState(null)
	const [contentWarning, setContentWarning] = useState(null)
	const [tagsWarning, setTagsWarning] = useState(null)
	const titleRef = useRef(null)
	const contentRef = useRef(null)
	const mounted = useRef(true)

	useEffect(() => {
		mounted.current = true
		getWritings()
		.then(items => {
			if(mounted.current) setWritings(items.data)
		})
		return () => mounted.current = false
	}, [])

	const handleTagsChange = newTags => {
		setTags(newTags)
		setTagsWarning(null)
		setShouldClearTags(false)
	}

	const handleSubmit = async e => {
		e.preventDefault()
		if (titleRef.current.value.trim() === '') {
			setTitleWarning('Title can\'t be blank.')
			return
		}
		if (contentRef.current.getContent() === '') {
			setContentWarning('Content can\'t be blank.')
			return
		}
		if (tags.length === 0) {
			setTagsWarning('Please select at least one tag.')
			return
		}
		const newWriting = {
			title: titleRef.current.value.trim(),
			content: contentRef.current.getContent()
		}
		if (editingWriting) {
			const response = await updateWritings({ ...newWriting, id: editingWriting.id }, 'PUT')
			if (response.code === '10') {
				Toast('success', 'Update writing successfully.', newWriting.title)
                const oldTagIds = editingWriting.hasTags.map(tag => tag.id)
                const newTagIds = tags.map(tag => tag.id)
                const tagsToDelete = oldTagIds.filter(id => !newTagIds.includes(id))
                const tagsToAdd = newTagIds.filter(id => !oldTagIds.includes(id))
                await Promise.all(tagsToDelete.map(tagId => 
                    updateWritingTags({ writing_id: editingWriting.id, tag_id: tagId }, 'DELETE')
                ))
                await Promise.all(tagsToAdd.map(tagId => 
                    updateWritingTags({ writing_id: editingWriting.id, tag_id: tagId }, 'POST')
                ))
				setEditingWriting(null)
			}
		} else {
			const response = await updateWritings(newWriting, 'POST')
			if (response.code === '8') {
				Toast('success', 'Create new writing successfully.', newWriting.title)
			}
			const newWritingId = response.data.id
			await Promise.all(tags.map(tag => 
				updateWritingTags({ writing_id: newWritingId, tag_id: tag.id }, 'POST')
			))
		}
		if (mounted.current) {
			titleRef.current.value = ''
			contentRef.current.setContent('')
			setShouldClearTags(true)
		}
		getWritings().then(items => setWritings(items.data))
	}

	const handleEdit = writing => {
		setEditingWriting(writing)
		titleRef.current.value = writing.title
		contentRef.current.setContent(writing.content)
		if (writing.hasTags.length) {
			setEditingTags(writing.hasTags)
		} else {
			setShouldClearTags(true)
		}
		window.scrollTo(0, 0)
	}

	const handleDelete = async writingId => {
		const resConfirmed = await Dialog('Confirm deletion', 'Are you sure you want to delete this writing?')
		if (resConfirmed) {
			const response = await updateWritings({id: writingId}, 'DELETE')
			if (response.code === '7') {
				Toast('success', 'Delete writing successfully.')
				getWritings().then(items => setWritings(items.data))
			}
		}
	}

	if (!token) return <Login setToken={setToken}/>
	return (
		<div className={clsx(styles.wrapper, 'container')}>
			<h1>Writing</h1>
			<form className={clsx(styles.form)} onSubmit={handleSubmit}>
				<div className={clsx(styles.field)}>
					<label htmlFor='title'>Title</label>
					<input id='title' type='text' placeholder='How to...' ref={titleRef}
						onChange={() => setTitleWarning(null)}
					/>
					<p className={titleWarning && styles.warning}>{titleWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label>Content</label>
					<Editor
						apiKey={infos.tinyApiKey}
						onInit={(_evt, editor) => contentRef.current = editor}
						onChange={() => setContentWarning(null)}
						key={theme}
						init={{
							min_height: 500,
							menu: {
								file: { title: 'File', items: 'newdocument restoredraft | print' },
								edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
								view: { title: 'View', items: 'code | visualaid visualchars visualblocks | preview fullscreen' },
								insert: { title: 'Insert', items: 'image link media codesample inserttable accordion | charmap emoticons hr | pagebreak nonbreaking anchor | insertdatetime' },
								format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontsize align lineheight | forecolor backcolor | removeformat' },
								help: { title: 'Help', items: 'help' }
							},
							menubar: 'file edit view insert format help',
							plugins: [
								'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'nonbreaking',
								'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'autosave', 'emoticons',
								'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'accordion',
								'pagebreak', 'visualchars', 'quickbars', 'codesample', 'save'
							],
							toolbar: 'save | blocks forecolor backcolor removeformat | hr blockquote link image codesample | align bullist numlist | indent outdent',
							toolbar_mode: 'sliding',
							quickbars_insert_toolbar: 'quickimage quicktable | hr pagebreak',
							quickbars_selection_toolbar: 'bold italic | blocks | quicklink blockquote',
							quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
							link_context_toolbar: true,
							branding: false,
							help_accessibility: false,
							setup: (editor) => {
								editor.on('click', () => {})
							},
							mobile: {
								menubar: 'file edit view insert format help',
							},
							contextmenu: 'link image table',
							newdocument_content: '<p>New document</p>',
							resize_img_proportional: false,
							indentation: '20px',
							link_default_target: '_blank',
							autosave_interval: '15s',
							save_onsavecallback: () => {},
							content_style: `
								@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
								body {
									font-family: 'Inter', sans-serif;
									color: ${theme === 'dark' ? '#ffffff' : '#1f1f1f'}
								}
							`
							// TODO: HARDCODE color
						}}
					/>
					<p className={contentWarning && styles.warning}>{contentWarning}</p>
				</div>
				<TagsPicker onTagsChange={handleTagsChange} tagsWarning={tagsWarning} shouldClearTags={shouldClearTags} editingTags={editingTags}/>
				<button type="submit" className={clsx(styles.submit, 'btn active')}>
					{editingWriting ? 'Update' : 'Create'}
				</button>
			</form>
			<div className={clsx(styles.data)}>
				<h2><Link to={routes.writing}>All Writing</Link></h2>
				<div className={clsx(styles.tableWrapper)}>
					<table>
						<thead>
							<tr>
								<th>Published</th>
								<th>Title</th>
								<th>Content</th>
								<th>Tags</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{writings && writings.map(writing => (
								<tr key={writing.id}>
									<td>{`${writing.published}, ${writing.year}`}</td>
									<td className={clsx(styles.writingTitle)}><Link to={`${routes.writing}/${writing.slug}`}>{writing.title}</Link></td>
									<td>{writing.content.length}</td>
									<td className={clsx(styles.writingTags)}>
										{writing.hasTags.map(tag => (
											<Link to={`${routes.tags}/${tag.name}`} key={tag.id}>{tag.name}</Link>
										))}
									</td>
									<td className={clsx(styles.action)}>
										<Link onClick={() => handleEdit(writing)}><FontAwesomeIcon icon={faPen}/></Link>
										<Link onClick={() => handleDelete(writing.id)}><FontAwesomeIcon icon={faTrash}/></Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export { Writing }