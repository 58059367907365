import clsx from 'clsx'
import styles from './Tags.module.scss'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { getTags } from '~/services/tags'

function TagsDetails() {
    const { tagName } = useParams()
    document.title = `Posts tags: ${tagName}` + titles.default
    useEffect(() => window.scrollTo(0, 0), [])

    const [tag, setTag] = useState([])

	useEffect(() => {
		let mounted = true
		getTags()
		.then(items => {
			if(mounted) {
                const findItem = items.data.find(item => item.name === tagName)
                setTag(findItem)
            }
		})
		return () => mounted = false
	}, [tagName])

    const result = []
    if (tag.inWritings) {
        const sortedData = tag.inWritings.sort((a, b) => b.year - a.year)
        for (const item of sortedData) {
            if (result.length === 0 || result[result.length - 1][0].year !== item.year) {
                result.push([item])
            } else {
                result[result.length - 1].push(item)
            }
        }
    }

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <p><span>{tag.inWritings && tag.inWritings.length}</span> posts tagged:</p>
                <h1>{tagName}</h1>
            </section>
            {result.map((yearWritings, index) => (
                <section className={clsx(styles.postTags)} key={index}>
                    <div className='sectionHead'>
                        <h2 className='sectionHeadYear'>{yearWritings[0].year}</h2>
                    </div>
                    <div className='articleList'>
                        {yearWritings.map(writing => (
                            <Link key={writing.id} to={`${routes.writing}/${writing.slug}`} className='articleItem'>
                                <h3>{writing.title}</h3>
                                <span></span>
                                <p>{writing.published}</p>
                            </Link>
                        ))}
                    </div>
                </section>
            ))}
        </div>
    )
}

export { TagsDetails }