import { useState, useEffect, useContext, createContext } from 'react'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'dark')

    const setDarkMode = () => {
        setTheme('dark')
        document.body.setAttribute('data-theme', 'dark')
        localStorage.setItem('theme', 'dark')
    }

    const setLightMode = () => {
        setTheme('light')
        document.body.setAttribute('data-theme', 'light')
        localStorage.setItem('theme', 'light')
    }

    const toggleTheme = () => {
        theme === 'dark' ? setLightMode() : setDarkMode()
    }

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme')
        document.body.setAttribute('data-theme', savedTheme || 'dark')
    }, [])

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeContext)
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider')
    }
    return context
}