import infos from "~/config/infos"

const tokenObj = JSON.parse(localStorage.getItem('token') ?? '{}')

export function getTags() {
    return fetch(`${infos.apiUrl}/tags`)
        .then(data => data.json())
}

export function updateTags(item, method) {
    return fetch(`${infos.apiUrl}/tags?token=` + tokenObj.token, {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(item)
    })
    .then(data => data.json())
}