import clsx from 'clsx'
import { useEffect, useState } from 'react'
import styles from './Admin.module.scss'
import Login from './Login'
import useToken from './useToken'
import titles from '~/config/titles'
import { getWritings } from '~/services/writings'
import { getTags } from '~/services/tags'
import { getProjects } from '~/services/projects'
import { getQuotes } from '~/services/quotes'
import { getPayments } from '~/services/payments'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFolder,
	faHashtag,
	faMoneyCheckDollar,
	faPenToSquare,
	faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'

function Dashboard() {
	document.title = titles.dashboard
	useEffect(() => window.scrollTo(0, 0), [])

	const { token, setToken } = useToken()
	const [writings, setWritings] = useState([])
	const [tags, setTags] = useState([])
	const [projects, setProjects] = useState([])
	const [quotes, setQuotes] = useState([])
	const [payment, setPayment] = useState([])

	useEffect(() => {
		let mounted = true
		getWritings()
		.then(items => {
			if(mounted) setWritings(items.data)
		})
		getTags()
		.then(items => {
			if(mounted) setTags(items.data)
		})
		getProjects()
		.then(items => {
			if(mounted) setProjects(items.data)
		})
		getQuotes()
		.then(items => {
			if(mounted) setQuotes(items.data)
		})
		getPayments()
		.then(items => {
			if(mounted) setPayment(items.data)
		})
		return () => mounted = false
	}, [])

	if (!token) return <Login setToken={setToken}/>
	return (
		<div className={clsx(styles.wrapper, 'container')}>
			<h1>Dashboard</h1>
			<ul className={clsx(styles.stasList)}>
				<li className={clsx(styles.stasItem)}>
					<FontAwesomeIcon icon={faPenToSquare}/>
					<div className={clsx(styles.stasItemText)}>
						<p>Writing</p>
						<span>{writings && writings.length}</span>
					</div>
				</li>
				<li className={clsx(styles.stasItem)}>
					<FontAwesomeIcon icon={faHashtag}/>
					<div className={clsx(styles.stasItemText)}>
						<p>Tags</p>
						<span>{tags && tags.length}</span>
					</div>
				</li>
				<li className={clsx(styles.stasItem)}>
					<FontAwesomeIcon icon={faFolder}/>
					<div className={clsx(styles.stasItemText)}>
						<p>Projects</p>
						<span>{projects && projects.length}</span>
					</div>
				</li>
				<li className={clsx(styles.stasItem)}>
					<FontAwesomeIcon icon={faQuoteLeft}/>
					<div className={clsx(styles.stasItemText)}>
						<p>Quotes</p>
						<span>{quotes && quotes.length}</span>
					</div>
				</li>
				<li className={clsx(styles.stasItem)}>
					<FontAwesomeIcon icon={faMoneyCheckDollar}/>
					<div className={clsx(styles.stasItemText)}>
						<p>Payment</p>
						<span>{payment && payment.length}</span>
					</div>
				</li>
			</ul>
		</div>
	)
}

export { Dashboard }