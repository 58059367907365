import infos from "~/config/infos"

const tokenObj = JSON.parse(localStorage.getItem('token') ?? '{}')

export function getProjects() {
    return fetch(`${infos.apiUrl}/projects`)
        .then(data => data.json())
}

export function updateProjects(item, method) {
    return fetch(`${infos.apiUrl}/projects?token=` + tokenObj.token, {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(item)
    })
    .then(data => data.json())
}