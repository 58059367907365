import clsx from 'clsx'
import styles from './About.module.scss'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import titles from '~/config/titles'
import infos from '~/config/infos'
import routes from '~/config/routes'

function About() {
    document.title = titles.about
    useEffect(() => window.scrollTo(0, 0), [])

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <div className='pageHead'>
                <h1>About</h1>
            </div>
            <div className={clsx(styles.aboutIntro)}>
                <p>I've been making websites since 2020. Since I was a child, I've always been fascinated with computers and how they operate. I've always loved creative video games, ones where I can build my own worlds, and learning how to program was an evolution of that interest. When I'm not at the computer, I'm usually reading, hanging out with my friends and traveling.</p>
                <p>My goal is to always build applications that are scalable and efficient under the hood while providing engaging, pixel-perfect user experiences. This is my spot on the web for <Link to={routes.writing}>writing</Link>, <Link to={routes.projects}>projects</Link>, tutorials, art, and some <Link to={routes.quotes}>quotes</Link> or anything else I want to put out there. This site has no ads, no affiliate links, no tracking or analytics, no sponsored posts, and no paywall. My motivation for this site is to have a space for self-expression and to share what I've learned with the world.</p>
                <p>I hope I will inspire others to make their own creative corner on the web as well. And feel free to contact me by <Link to={'mailto:' + infos.gmail}>email</Link> to say hi!</p>
            </div>
            <div className='pageContent'>
                <h2>Experience</h2>
                <p>I started learning HTML, CSS, Javascript and working on small projects in 2022, and now I'm working even harder to become a full-stack developer. </p>
                <h2>Skills</h2>
                <ul>
                    <li><strong>Languages</strong> - HTML, CSS, SASS, Javascript, Python, C++, Java, PHP, SQL</li>
                    <li><strong>Concepts</strong> - Web Application Development, Design Patterns (MVC), Databases (MySQL), Version Control (Git)</li>
                    <li><strong>Framework</strong> - React</li>
                    <li><strong>Tools</strong> - Bash, Git & Github, Chrome DevTools, Postman, MongoDB</li>
                    <li><strong>Design</strong> - Canva, Illustrator, Figma, Photoshop, CapCut, After Effects, Premiere Pro</li>
                </ul>
                <h2>Education</h2>
                <ul>
                    <li><p>Vietnam - Korea University of Information and Communication Technology<br/>2023 - now</p></li>
                </ul>
                <h2>Publications</h2>
                <ul><li><i>Not yet</i></li></ul>
                <h2>Books worth re-reading</h2>
                <ul><li><i>Not yet</i></li></ul>
                <h2>Podcasts I listen to</h2>
                <ul><li><i>Not yet</i></li></ul>
                <h2>Blogs I read</h2>
                <ul><li><i>Not yet</i></li></ul>
                <h2>Speaking</h2>
                <ul><li><i>Not yet</i></li></ul>
                <h2>Stuff</h2>
                <p>Inspired by internet, here's a collection of things I use on a daily basis, from software to hardware, for work and for fun.</p>
                <ul>
                    <li>
                        <p><strong>Hardware</strong></p>
                        <ul>
                            <li><p>Laptop: ASUS TUF GAMING F17</p></li>
                            <li><p>Monitor: E-DRA EGM22F75</p></li>
                            <li><p>Mouse: Logitech MX Master 3S</p></li>
                            <li><p>Keyboard: Xinmeng M87 Pro v2</p></li>
                            <li><p>Wired headphones: Moondrop LAN</p></li>
                        </ul>
                    </li>
                    <li>
                        <p><strong>Software</strong></p>
                        <ul>
                            <li><p>Coding: Visual Studio Code</p></li>
                            <li><p>Notes: Supernotes</p></li>
                            <li><p>Password manager: 1Password</p></li>
                        </ul>
                    </li>
                </ul>
                <h2>Miscellaneous</h2>
                <ul>
                    <li><i>Not yet</i></li>
                </ul>
                <h3>About the site</h3>
                <p>This site is:</p>
                <ul>
                    <li>Designed and coded on a Windows in Vietnam</li>
                    <li>Coded with Visual Studio Code</li>
                    <li>Hosted on 123host</li>
                    <li>The design and code powering this site, however, is not available for re-use or purchase.</li>
                </ul>
                <p>Feel free to contact me about anything, whether its a question, some feedback, or just to say hi! I try to respond to all messages as soon as possible. Alternatively, you can contact me via email at <a href={`mailto:${infos.gmail}`}>{infos.gmail}</a>. Although I'm not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I'll try my best to get back to you!</p>
            </div>
        </div>
    )
}

export default About