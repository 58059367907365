// <div className={clsx(styles.field)}>
// 	<label htmlFor='input'>Label</label>
// 	<input id='input' type='text' placeholder='Placeholder'/>
// 	<p>Some additional Info</p>
// </div>

// <textarea placeholder='Placeholder'></textarea>

// <div className={clsx(styles.radioGroup, styles.vertical)}>
// 	<label>
// 		<input type='radio' name='radioGroup'/>
// 		<span>Hello</span>
// 	</label>
// 	<label>
// 		<input type='radio' name='radioGroup'/>
// 		<span>Hello</span>
// 	</label>
// 	<label>
// 		<input type='radio' name='radioGroup'/>
// 		<span>Hello</span>
// 	</label>
// 	<label>
// 		<input type='radio' name='radioGroup'/>
// 		<span>Hello</span>
// 	</label>
// </div>

// <label className={clsx(styles.switch)}>
// 	<input type='checkbox'/>
// 	<span></span>
// 	<p>Label</p>
// </label>

// <label className={clsx(styles.checkbox)}>
// 	<input type="checkbox"/>
// 	<span></span>
// 	<p>Label</p>
// </label>

// <label className={clsx(styles.combobox)}>
// 	<span>Framework</span>
// 	<input type='text' placeholder='Select a Framework'/>
// 	<div className={clsx(styles.iconbox)}>
// 		<FontAwesomeIcon icon={faAngleUp}/>
// 		<FontAwesomeIcon icon={faAngleDown}/>
// 	</div>
// 	<ul className={clsx(styles.comboboxList)}>
// 		<li className={clsx(styles.comboboxItem)}>React <FontAwesomeIcon icon={faCheck}/></li>
// 		<li className={clsx(styles.comboboxItem)}>Solid</li>
// 		<li className={clsx(styles.comboboxItem)}>Svelte</li>
// 		<li className={clsx(styles.comboboxItem)}>Vue</li>
// 	</ul>
// </label>

import clsx from 'clsx'
import styles from './Admin.module.scss'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import titles from '~/config/titles'
import infos from '~/config/infos'
import { Toast } from '~/utils/Noti'

async function loginUser(credentials) {
    return fetch(`${infos.apiUrl}/login`, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(credentials)
    })
    .then(data => data.json())
}

function Login({ setToken }) {
    document.title = titles.login
	useEffect(() => window.scrollTo(0, 0), [])

    const [username, setUserName] = useState()
    const [password, setPassword] = useState()
    const [usernameWarning, setUsernameWarning] = useState(null)
    const [passwordWarning, setPasswordWarning] = useState(null)

    const handleSubmit = async e => {
        e.preventDefault()
		if (!username || username.trim() === '') {
			setUsernameWarning('Please provide username.')
		}
		if (!password || password.trim() === '') {
			setPasswordWarning('Please provide password.')
		}
        if (username && password) {
			const data = await loginUser({
				username,
				password
			})
			switch (data.code) {
				case '11':
					setToken(data.data)
					Toast('success', data.data.message)
					break
				case '12':
					Toast('error', data.data)
					break
				case '13':
					Toast('error', data.data)
					break
				default:
					Toast('error', 'Something wrong!')
					break
			}
		}
    }

	return (
		<div className={clsx(styles.wrapper, 'container', styles.loginWrapper)}>
			<h1>Login</h1>
			<form onSubmit={handleSubmit}>
				<div className={clsx(styles.field)}>
					<label htmlFor='username'>Username</label>
					<input id='username' type='text' placeholder='admin' value={username}
						onChange={e => {
							setUserName(e.target.value)
							setUsernameWarning(null)
						}}
					/>
					<p className={usernameWarning && clsx(styles.warning)}>{usernameWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='password'>Password</label>
					<input id='password' type='text' placeholder='******' value={password}
						onChange={e => {
							setPassword(e.target.value)
							setPasswordWarning(null)
						}}
					/>
					<p className={passwordWarning && clsx(styles.warning)}>{passwordWarning}</p>
				</div>
				<button type="submit" className={clsx(styles.submit, 'btn active')}>Submit</button>
			</form>
		</div>
	)
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login