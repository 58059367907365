import clsx from 'clsx'
import styles from './Home.module.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import ZLetter from '~/assets/images/Z-letter.png'
import { getWritings } from '~/services/writings'
import { getProjects } from '~/services/projects'
import { getQuotes } from '~/services/quotes'

function Home() {
    document.title = titles.home
    useEffect(() => window.scrollTo(0, 0), [])

    const [writings, setWritings] = useState([])
	const [projects, setProjects] = useState([])
	const [quotes, setQuotes] = useState([])

	useEffect(() => {
		let mounted = true
		getWritings()
		.then(items => {
			if(mounted) setWritings(items.data)
		})
		getProjects()
		.then(items => {
			if(mounted) setProjects(items.data)
		})
		getQuotes()
		.then(items => {
			if(mounted) setQuotes(items.data)
		})
		return () => mounted = false
	}, [])

    writings.length = 5
    projects.length = 6
    quotes.length = 1

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className={clsx(styles.intro)}>
                <div className={clsx(styles.introContent)}>
                    <h1>Hey, I'm Zoohuy</h1>
                    <p>I'm a software developer who makes open-source projects and writes about life, code, design, and more.</p>
                    <p>I'm passionate about building products that help people, make their lives easier and look good while doing it. Welcome to my digital garden 🌱.</p>
                </div>
                <div className={clsx(styles.introImage)}><img src={ZLetter} alt='Z letter'/></div>
            </section>
            <section className={clsx(styles.writing)}>
                <div className='sectionHead'>
                    <h2 className='sectionHeadHeading'>Writing</h2>
                    <Link to={routes.writing} className='btn active normal-btn'>All Writing</Link>
                </div>
                <div className='articleList'>
                    {writings && writings.map(writing => (
                        <Link key={writing.id} to={`${routes.writing}/${writing.slug}`} className='articleItem'>
                            <h3>{writing.title}</h3>
                            <span></span>
                            <p>{`${writing.published}, ${writing.year}`}</p>
                        </Link>
                    ))}
                </div>
            </section>
            <section className={clsx(styles.projects)}>
                <div className='sectionHead'>
                    <h2 className='sectionHeadHeading'>Projects</h2>
                    <Link to={routes.projects} className='btn active normal-btn'>All Projects</Link>
                </div>
                <div className='projectList'>
                    {projects && projects.map(project => (
                        <div className='projectItem' key={project.id}>
                            <span>{project.year_complete}</span>
                            <Link to={project.demo_url} target='_blank' className='projectArticleLink'>{project.title}</Link>
                            <p>{project.description}</p>
                            <div className='projectLinkList'>
                                <Link to={project.source_url} target='__blank' className='btn active sm normal-btn'>Source
                                <FontAwesomeIcon className='iconNewTab' icon={faArrowUpRightFromSquare}/></Link>
                                <Link to={project.demo_url} target='__blank' className='btn active sm normal-btn'>Demo
                                <FontAwesomeIcon className='iconNewTab' icon={faArrowUpRightFromSquare}/></Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className={clsx(styles.quotes)}>
                <div className='sectionHead'>
                    <h2 className='sectionHeadHeading'>Quotes</h2>
                    <Link to={routes.quotes} className='btn active normal-btn'>All Quotes</Link>
                </div>
                <div className='quotesList'>
                    {quotes && quotes.map(quote => (
                        <div className='quotesItem' key={quote.id}>
                            <FontAwesomeIcon className='quoteIcon' icon={faQuoteLeft}/>
                            <h3>{quote.content}</h3>
                            <span>{quote.source}</span>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Home