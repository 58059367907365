import clsx from 'clsx'
import styles from './Header.module.scss'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTheme } from '../ThemeContext'
import routes from '~/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faUserTie, faXmark } from '@fortawesome/free-solid-svg-icons'
import logoLight from '~/assets/images/logo-light.svg'
import logoDark from '~/assets/images/logo-dark.svg'
import lightIcon from '~/assets/icons/light.svg'
import darkIcon from '~/assets/icons/dark.svg'

function Header() {
    const { theme, toggleTheme } = useTheme()
    const [menuBar, setMenuBar] = useState(false)
    const isLogin = localStorage.getItem('token') &&
                    JSON.parse(localStorage.getItem('token')).message === 'Login successfull.'

    return (
        <div className={clsx(styles.wrapper)}>
            <div className={clsx(styles.content, 'container')}>
                <Link to={routes.home} className={clsx(styles.logo)}>
                    <img src={theme === 'dark' ? logoDark : logoLight} alt='Zoohuy logo'/>
                    <span>Zoohuy</span>
                </Link>
                <div className={clsx(styles.nav)}>
                    <NavLink to={routes.home} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Home</NavLink>
                    <FontAwesomeIcon icon={faXmark} className={clsx(styles.navCloseIcon)} onClick={() => setMenuBar(false)}/>
                    <NavLink to={routes.about} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>About</NavLink>
                    <NavLink to={routes.writing} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Writing</NavLink>
                    <NavLink to={routes.projects} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Projects</NavLink>
                    <NavLink to={routes.quotes} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Quotes</NavLink>
                    <NavLink to={routes.payment} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Payment</NavLink>
                    <div className={clsx('btn', styles.navLink, styles.navLinkIcon)} onClick={toggleTheme}>
                        <div><img src={theme === 'dark' ? lightIcon : darkIcon} alt={theme === 'dark' ? 'Dark theme icon' : 'Light theme icon'}/></div>
                    </div>
                    {isLogin && <Link to={routes.dashboard} className={clsx('btn', styles.navLink, styles.navLinkIcon)}>
                        <FontAwesomeIcon className={clsx(styles.adminIcon)} icon={faUserTie}/>
                    </Link>}
                </div>
                <div className={clsx(styles.menuBar, 'btn active normal-btn', {
                    [styles.active]: menuBar
                })} onClick={() => setMenuBar(true)}>
                    <span>Menu</span>
                    <FontAwesomeIcon className={clsx(styles.menuBarIcon)} icon={faChevronDown}/>
                </div>
                <div className={clsx(styles.menuModal)} onClick={() => setMenuBar(false)}></div>
            </div>
        </div>
    )
}

export default Header