const routes = {
    home: '/',
    about: '/about',
    writing: '/writing',
    writingDetails: '/writing/:slug',
    projects: '/projects',
    quotes: '/quotes',
    payment: '/payment',
    tags: '/tags',
    tagsDetails: '/tags/:tagName',
    
    // Admin routes
    dashboard: '/admin',
    adminWriting: '/admin/writing',
    adminTags: '/admin/tags',
    adminProjects: '/admin/projects',
    adminQuotes: '/admin/quotes',
    adminPayment: '/admin/payment'
}

export default routes