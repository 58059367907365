import clsx from 'clsx'
import styles from '../Footer/Footer.module.scss'
import { Link } from 'react-router-dom'
import { useTheme } from '../ThemeContext'
import routes from '~/config/routes'
import logoLight from '~/assets/images/logo-light-2.svg'
import logoDark from '~/assets/images/logo-dark-2.svg'

function Footer() {
    const { theme } = useTheme()
    
    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <Link className={clsx(styles.footerLogo)} to={routes.dashboard}><img src={theme === 'dark' ? logoDark : logoLight} alt='Zoohuy logo 2'/></Link>
            <p className={clsx(styles.footerCopyright)}>© 2024 Zoohuy • All rights reserved.</p>
        </div>
    )
}

export default Footer