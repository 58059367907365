import clsx from 'clsx'
import styles from './Writing.module.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { getWritings } from '~/services/writings'

function Writing() {
    document.title = titles.writing
    useEffect(() => window.scrollTo(0, 0), [])

    const [writings, setWritings] = useState([])

	useEffect(() => {
		let mounted = true
		getWritings()
		.then(items => {
			if(mounted) setWritings(items.data)
		})
		return () => mounted = false
	}, [])

    const sortedData = writings.sort((a, b) => b.year - a.year)
    const result = []
    for (const item of sortedData) {
        if (result.length === 0 || result[result.length - 1][0].year !== item.year) {
            result.push([item])
        } else {
            result[result.length - 1].push(item)
        }
    }

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <h1>Writing</h1>
                <h2>Personal notes about life, music, art, projects, and everything else I want to write about. Guides, references, and tutorials on programming, web development, and design.</h2>
                <Link className='btn sm active link-btn' to={routes.tags}>View All Tags</Link>
            </section>
            {result && result.map((yearWritings, index) => (
                <section className={clsx(styles.writing)} key={index}>
                    <div className='sectionHead'>
                        <h2 className='sectionHeadYear'>{yearWritings[0].year}</h2>
                    </div>
                    <div className='articleList'>
                        {yearWritings.map(writing => (
                            <Link key={writing.id} to={`${routes.writing}/${writing.slug}`} className='articleItem'>
                                <h3>{writing.title}</h3>
                                <span></span>
                                <p>{writing.published}</p>
                            </Link>
                        ))}
                    </div>
                </section>
            ))}
        </div>
    )
}

export { Writing }