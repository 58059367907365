import clsx from 'clsx'
import styles from './Payment.module.scss'
import { useEffect, useState } from 'react'
import titles from '~/config/titles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import itKhoaiLuoc from '~/assets/images/it-khoai-luoc.png'
import { getPayments } from '~/services/payments'

function Payment() {
    document.title = titles.payment
    useEffect(() => window.scrollTo(0, 0), [])

    const [payments, setPayments] = useState([])

	useEffect(() => {
		let mounted = true
		getPayments()
		.then(items => {
			if(mounted) setPayments(items.data)
		})
		return () => mounted = false
	}, [])

    const handleCopy = e => {
        let targetSpan = e.target.closest(`.${clsx(styles.paymentItemInfoNumber)} span`)
        let spanBText = targetSpan.querySelector('b').innerText
        if (targetSpan) {
            let inputE = targetSpan.parentNode.parentNode.querySelector('input')
            inputE.select()
            inputE.setSelectionRange(0, 99999)
            navigator.clipboard.writeText(inputE.value)
        }
        targetSpan.querySelector('b').innerText = 'Copied'
        targetSpan.querySelector('b').classList.add(clsx(styles.copied))
        setTimeout(() => {
            targetSpan.querySelector('b').innerText = spanBText
            targetSpan.querySelector('b').classList.remove(clsx(styles.copied))
        }, 1500)
    }

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <h1>Payment</h1>
                <h2>Securely process payments for services or send a donation to support my work.</h2>
            </section>
            <section className={clsx(styles.paymentList)}>
                {payments && payments.map(payment => (
                    <div className={clsx(styles.paymentItem)} key={payment.id}>
                        <div className={clsx(styles.paymentItemQr)}>
                            <img src={payment.qr_img_url} alt={`${payment.bank_name} QR: ${payment.acc_number} ${payment.acc_holder}`}/>
                        </div>
                        <div className={clsx(styles.paymentItemInfo)}>
                            <div className={clsx(styles.paymentItemInfoBank)}>
                                <p>Bank name: <span>{payment.bank_name}</span></p>
                                <img src={payment.bank_img_url} alt={`${payment.bank_name} logo`}/>
                            </div>
                            <div className={clsx(styles.paymentItemInfoNumber)}>
                                <p>Account number: <span onClick={handleCopy}><b>{payment.acc_number}</b>
                                    <FontAwesomeIcon icon={faCopy} className={clsx(styles.copyIcon)}/>
                                </span></p>
                                <input value={payment.acc_number} readOnly/>
                            </div>
                            <p>Account holder: <span>{payment.acc_holder}</span></p>
                        </div>
                    </div>
                ))}
            </section>
            <img className={clsx(styles.itKhoaiLuoc)} src={itKhoaiLuoc} alt='Bố làm IT mà nhà mình vẫn ăn khoai luộc hở mẹ. Bố mày làm vì đam mê chứ ăn thua gì.'/>
        </div>
    )
}

export default Payment