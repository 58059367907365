const titles = {
    default: ' | Zoohuy',
    home: 'Zoohuy',
    about: 'About | Zoohuy',
    writing: 'Writing | Zoohuy',
    projects: 'Projects | Zoohuy',
    quotes: 'Quotes | Zoohuy',
    payment: 'Payment | Zoohuy',
    tags: 'Tags | Zoohuy',
    notFound: 'Page not found | Zoohuy',
    
    // Admin titles
    login: 'Login | ZoohuyAdmin',
    dashboard: 'Dashboard | ZoohuyAdmin',
    adminWriting: 'Writing | ZoohuyAdmin',
    adminTags: 'Tags | ZoohuyAdmin',
    adminProjects: 'Projects | ZoohuyAdmin',
    adminQuotes: 'Quotes | ZoohuyAdmin',
    adminPayment: 'Payment | ZoohuyAdmin'
}

export default titles