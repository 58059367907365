import clsx from 'clsx'
import styles from './Writing.module.scss'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import titles from '~/config/titles'
import routes from '~/config/routes'
import 'ckeditor5/ckeditor5.css'
import { getWritings } from '~/services/writings'

function WritingDetails() {
    const { slug } = useParams()
    const [writing, setWriting] = useState([])

	useEffect(() => {
		let mounted = true
		getWritings()
		.then(items => {
			if(mounted) {
                const findItem = items.data.find(item => item.slug === slug)
                setWriting(findItem)
            }
		})
		return () => mounted = false
	}, [slug])

    document.title = writing.title + titles.default
    useEffect(() => window.scrollTo(0, 0), [])

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <h1 className='writingDetail'>{writing.title}</h1>
            </section>
            <div className='pageContent' dangerouslySetInnerHTML={{ __html: writing.content }}></div>
            <div className={clsx(styles.postDetails, 'pageContent')}>
                <h5>Post Details</h5>
                <ul>
                    <li>
                        <b>Published:</b> {`${writing.published}, ${writing.year}`}
                    </li>
                    <li>
                        <b>Tags: </b>
                        <ul>
                            {writing.hasTags && writing.hasTags.map((tag, index) => (
                                <li key={tag.id}>
                                    <Link to={`${routes.tags}/${tag.name}`}>{tag.name}</Link>
                                    {writing.hasTags.length !== index + 1 ? ',' : ''}
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export { WritingDetails }