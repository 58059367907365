import { useState } from 'react'

function getCurrentDateTime() {
	const now = new Date()
	const year = now.getUTCFullYear()
	const month = String(now.getUTCMonth() + 1).padStart(2, '0')
	const day = String(now.getUTCDate()).padStart(2, '0')
	const hours = String(now.getUTCHours()).padStart(2, '0')
	const minutes = String(now.getUTCMinutes()).padStart(2, '0')
	const seconds = String(now.getUTCSeconds()).padStart(2, '0')
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function useToken() {
    const getToken = () => {
		const tokenString = localStorage.getItem('token')
		const userToken = JSON.parse(tokenString)
		const expiresDate = userToken?.expires_at
		const currentDateTime = getCurrentDateTime()
		if (currentDateTime > expiresDate) return null
		else return userToken?.token
    }
	
    const [token, setToken] = useState(getToken())

    const saveToken = userToken => {
		localStorage.setItem('token', JSON.stringify(userToken))
		setToken(userToken.token)
    }

    return {
		setToken: saveToken,
		token
    }
}

export default useToken