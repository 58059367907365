import clsx from 'clsx'
import styles from './Quotes.module.scss'
import { useEffect, useState } from 'react'
import titles from '~/config/titles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { getQuotes } from '~/services/quotes'

function Quotes() {
    document.title = titles.quotes
    useEffect(() => window.scrollTo(0, 0), [])

    const [quotes, setQuotes] = useState([])

	useEffect(() => {
		let mounted = true
		getQuotes()
		.then(items => {
			if(mounted) setQuotes(items.data)
		})
		return () => mounted = false
	}, [])

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <h1>Quotes</h1>
                <h2>Quote worth thinking about.</h2>
            </section>
            <section className={clsx(styles.quotes)}>
                <div className='quotesList'>
                    {quotes && quotes.map(quote => (
                        <div className='quotesItem' key={quote.id}>
                            <FontAwesomeIcon className='quoteIcon' icon={faQuoteLeft}/>
                            <h3>{quote.content}</h3>
                            <span>{quote.source}</span>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Quotes