import clsx from 'clsx'
import styles from '../Header/Header.module.scss'
import routes from '~/config/routes'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTheme } from '../ThemeContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faHome, faXmark } from '@fortawesome/free-solid-svg-icons'
import logoLight from '~/assets/images/logo-light-2.svg'
import logoDark from '~/assets/images/logo-dark-2.svg'
import lightIcon from '~/assets/icons/light.svg'
import darkIcon from '~/assets/icons/dark.svg'

function Header() {
    const { theme, toggleTheme } = useTheme()
    const [menuBar, setMenuBar] = useState(false)

    return (
        <div className={clsx(styles.wrapper)}>
            <div className={clsx(styles.content, 'container')}>
                <Link to={routes.dashboard} className={clsx(styles.logo)}>
                    <img src={theme === 'dark' ? logoDark : logoLight} alt='Zoohuy logo 2'/>
                    <span>ZoohuyAdmin</span>
                </Link>
                <div className={clsx(styles.nav)}>
                    <NavLink to={routes.dashboard} end className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Dashboard</NavLink>
                    <FontAwesomeIcon icon={faXmark} className={clsx(styles.navCloseIcon)} onClick={() => setMenuBar(false)}/>
                    <NavLink to={routes.adminWriting} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Writing</NavLink>
                    <NavLink to={routes.adminTags} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Tags</NavLink>
                    <NavLink to={routes.adminProjects} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Projects</NavLink>
                    <NavLink to={routes.adminQuotes} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Quotes</NavLink>
                    <NavLink to={routes.adminPayment} className={clsx('btn', styles.navLink)} onClick={() => setMenuBar(false)}>Payment</NavLink>
                    <div className={clsx('btn', styles.navLink, styles.navLinkIcon)} onClick={toggleTheme}>
                        <div><img src={theme === 'dark' ? lightIcon : darkIcon} alt={theme === 'dark' ? 'Dark theme icon' : 'Light theme icon'}/></div>
                    </div>
                    <Link to={routes.home} className={clsx('btn', styles.navLink, styles.navLinkIcon)}>
                        <FontAwesomeIcon className={clsx(styles.adminIcon)} icon={faHome}/>
                    </Link>
                </div>
                <div className={clsx(styles.menuBar, 'btn active normal-btn', {
                    [styles.active]: menuBar
                })} onClick={() => setMenuBar(true)}>
                    <span>Menu</span>
                    <FontAwesomeIcon className={clsx(styles.menuBarIcon)} icon={faChevronDown}/>
                </div>
                <div className={clsx(styles.menuModal)} onClick={() => setMenuBar(false)}></div>
            </div>
        </div>
    )
}

export default Header