import clsx from 'clsx'
import styles from '~/pages/Admin/Admin.module.scss'
import { useEffect, useRef, useState } from 'react'
import { faEraser, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTags } from '~/services/tags'

function TagsPicker({ onTagsChange, tagsWarning, shouldClearTags, editingTags }) {
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const inputRef = useRef(null)

    useEffect(() => {
		let mounted = true
		getTags()
        .then(items => {
            if(mounted) setTags(items.data)
        })
		return () => mounted = false
	}, [])

    useEffect(() => onTagsChange(selectedTags), [selectedTags])

    useEffect(() => {
        if (shouldClearTags) {
            setSelectedTags([])
            setSearchValue('')
        }
    }, [shouldClearTags])

    useEffect(() => {
        if (editingTags) {
            setSelectedTags(editingTags)
        }
    }, [editingTags])

    const handleSelectTag = selectedIndex => {
        const tagObj = tags[selectedIndex]
        setSelectedTags(prevSelectedTags => [
            ...prevSelectedTags,
            tagObj
        ])
        setTags(prevTags => prevTags.filter((_, index) => index !== selectedIndex))
        setTimeout(() => inputRef.current.focus(), 0)
    }

    const handleUnselectTag = unselectIndex => {
        const tagObj = selectedTags[unselectIndex]
        setTags(prevTags => [
            ...prevTags,
            tagObj
        ])
        setSelectedTags(prevSelectedTags =>
            prevSelectedTags.filter((_, index) => index !== unselectIndex)
        )
    }

    useEffect(() => {
        const searchTags = tags.filter(tag => tag.name.includes(searchValue))
        setTags(searchTags)
        if (searchValue === '') getTags().then(items => setTags(
            items.data.filter(tag => !selectedTags.some(selectedTag => selectedTag.id === tag.id))
        ))
	}, [searchValue, selectedTags])

    return (
        <div className={clsx(styles.field)}>
            <label htmlFor='tags'>Tags</label>
            <div className={clsx(styles.tagsInput)}>
                <ul>
                    {selectedTags.map((selectedTag, index) => (
                        <li key={index}><span>{selectedTag.name}</span><FontAwesomeIcon icon={faXmark}
                            onClick={() => handleUnselectTag(index)}
                        /></li>
                    ))}
                </ul>
                <input id='tags' placeholder='api, bash, computer,...' type='text' ref={inputRef} value={searchValue}
                    onChange={e => setSearchValue(e.target.value.trim().replaceAll(' ', ''))}
                    onKeyDown={e => {if (e.key === ' ') e.preventDefault()}}
                />
                <FontAwesomeIcon icon={faEraser}
                    onClick={() => {
                        setSelectedTags([])
                        setSearchValue('')
                        getTags().then(items => setTags(items.data))
                    }}
                />
            </div>
            <p className={tagsWarning && clsx(styles.warning)}>{tagsWarning}</p>
            <ul className={clsx(styles.comboboxList)}>
                {tags && tags.map((tag, index) => (
                    <li key={index} className={clsx(styles.comboboxItem)}
                        onMouseDown={() => handleSelectTag(index)}
                    >{tag.name}</li>
                ))}
                {tags.length === 0 && <li className={clsx(styles.comboboxItem, styles.notFound)}>No tag found.</li>}
            </ul>
        </div>
    )
}

export default TagsPicker