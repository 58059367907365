import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import { ThemeProvider } from '~/layouts/components/ThemeContext'
import { publicRoutes, privateRoutes } from '~/routes'
import Header from '~/layouts/components/Header'
import Footer from '~/layouts/components/Footer'
import AdminHeader from '~/layouts/components/AdminHeader'
import AdminFooter from '~/layouts/components/AdminFooter'
import NotFound from '~/pages/NotFound'

function NormalLayout() {
    return (
        <div className="App">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

function AdminLayout() {
    return (
        <div className="App">
            <AdminHeader/>
            <Outlet/>
            <AdminFooter/>
        </div>
    )
}

function App() {
    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<NormalLayout/>}>
                        {publicRoutes.map((route, index) => {
                            const Page = route.component
                            return <Route key={index} path={route.path} element={<Page/>}/>
                        })}
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                    <Route path="/admin" element={<AdminLayout/>}>
                        {privateRoutes.map((route, index) => {
                            const Page = route.component
                            return <Route key={index} path={route.path} element={<Page/>}/>
                        })}
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default App