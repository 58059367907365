import infos from "~/config/infos"

const tokenObj = JSON.parse(localStorage.getItem('token') ?? '{}')

export function updateWritingTags(item, method) {
    return fetch(`${infos.apiUrl}/writing_tags?token=` + tokenObj.token, {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(item)
    })
    .then(data => data.json())
}