import clsx from 'clsx'
import styles from './Projects.module.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import titles from '~/config/titles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { getProjects } from '~/services/projects'

function Projects() {
    document.title = titles.projects
    useEffect(() => window.scrollTo(0, 0), [])

    const [projects, setProjects] = useState([])

	useEffect(() => {
		let mounted = true
		getProjects()
		.then(items => {
			if(mounted) setProjects(items.data)
		})
		return () => mounted = false
	}, [])

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <h1>Projects</h1>
                <h2>Open-source projects I've made over the years, including this website, an emulator, and various games, apps, frameworks, and boilerplates. Things I've made trying to put my dent in the universe.</h2>
            </section>
            <section className={clsx(styles.projects)}>
                <div className={clsx('projectList', styles.projectList)}>
                    {projects && projects.map(project => (
                        <div className='projectItem' key={project.id}>
                            <span>{project.year_complete}</span>
                            <Link to={project.demo_url} target='_blank' className='projectArticleLink'>{project.title}</Link>
                            <p>{project.description}</p>
                            <div className='projectLinkList'>
                                <Link to={project.source_url} target='__blank' className='btn active sm normal-btn'>Source
                                <FontAwesomeIcon className='iconNewTab' icon={faArrowUpRightFromSquare}/></Link>
                                <Link to={project.demo_url} target='__blank' className='btn active sm normal-btn'>Demo
                                <FontAwesomeIcon className='iconNewTab' icon={faArrowUpRightFromSquare}/></Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Projects