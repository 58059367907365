import clsx from 'clsx'
import styles from './Footer.module.scss'
import { Link } from 'react-router-dom'
import { useTheme } from '../ThemeContext'
import routes from '~/config/routes'
import infos from '~/config/infos'
import logoLight from '~/assets/images/logo-light.svg'
import logoDark from '~/assets/images/logo-dark.svg'
import coffeeIcon from '~/assets/icons/coffee.png'

function Footer() {
    const { theme } = useTheme()
    
    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <Link className={clsx(styles.footerLogo)} to={routes.home}><img src={theme === 'dark' ? logoDark : logoLight} alt='Zoohuy logo'/></Link>
            <div className={clsx(styles.footerSocialList)}>
                <Link to={routes.payment} className={clsx('btn active md normal-btn', styles.footerSocialItem)}><img src={coffeeIcon} alt='Donate a Coffee'/> Donate a Coffee</Link>
            </div>
            <div className={clsx(styles.footerLinkList)}>
                <Link to={'mailto:' + infos.gmail} className={clsx(styles.footerLinkItem)}>Gmail</Link>
                <Link to={infos.facebook} target='__blank' className={clsx(styles.footerLinkItem)}>Facebook</Link>
                <Link to={infos.telegram} target='__blank' className={clsx(styles.footerLinkItem)}>Telegram</Link>
                <Link to={infos.github} target='__blank' className={clsx(styles.footerLinkItem)}>GitHub</Link>
                <Link to={infos.linkedin} target='__blank' className={clsx(styles.footerLinkItem)}>LinkedIn</Link>
            </div>
            <p className={clsx(styles.footerCopyright)}>© 2024 Zoohuy • All rights reserved.</p>
        </div>
    )
}

export default Footer