const infos = {
    gmail: 'zoohuy.contact@gmail.com',
    facebook: 'https://facebook.com/zoohuyvn',
    telegram: 'https://t.me/zoohuyvn',
    github: 'https://github.com/zoohuyvn',
    linkedin: 'https://linkedin.com/in/zoohuyvn',
    tinyApiKey: '46p0n4f3v6g2dxanmv0lmac8l51371argfwtw2zrmc7v9enm',
    apiUrl: 'https://zoohuy.net/api'
}

export default infos