import clsx from 'clsx'
import styles from './Admin.module.scss'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Login from './Login'
import useToken from './useToken'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getPayments, updatePayments } from '~/services/payments'
import { Dialog, Toast } from '~/utils/Noti'

function Payment() {
    document.title = titles.adminPayment
	useEffect(() => window.scrollTo(0, 0), [])

	const { token, setToken } = useToken()
	const [payments, setPayments] = useState([])
	const [editingPayment, setEditingPayment] = useState(null)
	const [bankNameWarning, setBankNameWarning] = useState(null)
	const [accNumberWarning, setAccNumberWarning] = useState(null)
	const [accHolderWarning, setAccHolderWarning] = useState(null)
	const [qrImgUrlWarning, setQrImgUrlWarning] = useState(null)
	const [bankImgUrlWarning, setBankImgUrlWarning] = useState(null)
	const bankNameRef = useRef(null)
	const accNumberRef = useRef(null)
	const accHolderRef = useRef(null)
	const qrImgUrlRef = useRef(null)
	const bankImgUrlRef = useRef(null)
	const mounted = useRef(true)

	useEffect(() => {
		mounted.current = true
		getPayments()
		.then(items => {
			if(mounted.current) setPayments(items.data)
		})
		return () => mounted.current = false
	}, [])

	const handleSubmit = async e => {
		e.preventDefault()
		if (bankNameRef.current.value.trim() === '') {
			setBankNameWarning('Bank name can\'t be blank.')
			return
		}
		if (accNumberRef.current.value.trim() === '') {
			setAccNumberWarning('Account number can\'t be blank.')
			return
		}
		if (accHolderRef.current.value.trim() === '') {
			setAccHolderWarning('Account holder can\'t be blank.')
			return
		}
		if (qrImgUrlRef.current.value.trim() === '') {
			setQrImgUrlWarning('QR image url can\'t be blank.')
			return
		}
		if (bankImgUrlRef.current.value.trim() === '') {
			setBankImgUrlWarning('Bank image url can\'t be blank.')
			return
		}
		const newPayment = {
			bank_name: bankNameRef.current.value.trim(),
			acc_number: accNumberRef.current.value.trim(),
			acc_holder: accHolderRef.current.value.trim(),
			qr_img_url: qrImgUrlRef.current.value.trim(),
			bank_img_url: bankImgUrlRef.current.value.trim()
		}
		if (editingPayment) {
			const response = await updatePayments({ ...newPayment, id: editingPayment.id }, 'PUT')
			if (response.code === '10') {
				Toast('success', 'Update payment successfully.', newPayment.name)
				setEditingPayment(null)
			}
		} else {
			const response = await updatePayments(newPayment, 'POST')
			if (response.code === '8') {
				Toast('success', 'Create new payment successfully.', newPayment.name)
			}
		}
		if (mounted.current) {
			bankNameRef.current.value = ''
			accNumberRef.current.value = ''
			accHolderRef.current.value = ''
			qrImgUrlRef.current.value = ''
			bankImgUrlRef.current.value = ''
		}
		getPayments().then(items => setPayments(items.data))
	}

	const handleEdit = payment => {
		setEditingPayment(payment)
		bankNameRef.current.value = payment.bank_name
		accNumberRef.current.value = payment.acc_number
		accHolderRef.current.value = payment.acc_holder
		qrImgUrlRef.current.value = payment.qr_img_url
		bankImgUrlRef.current.value = payment.bank_img_url
		window.scrollTo(0, 0)
	}

	const handleDelete = async paymentId => {
		const resConfirmed = await Dialog('Confirm deletion', 'Are you sure you want to delete this payment?')
		if (resConfirmed) {
			const response = await updatePayments({id: paymentId}, 'DELETE')
			if (response.code === '7') {
				Toast('success', 'Delete payment successfully.')
				getPayments().then(items => setPayments(items.data))
			}
		}
	}
	
	if (!token) return <Login setToken={setToken}/>
	return (
		<div className={clsx(styles.wrapper, 'container')}>
			<h1>Payment</h1>
			<form className={clsx(styles.form)} onSubmit={handleSubmit}>
				<div className={clsx(styles.field)}>
					<label htmlFor='bank_name'>Bank name</label>
					<input id='bank_name' type='text' placeholder='MB Bank (Ngân hàng Quân đội)' ref={bankNameRef}
						onChange={() => setBankNameWarning(null)}
					/>
					<p className={bankNameWarning && styles.warning}>{bankNameWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='acc_number'>Account number</label>
					<input id='acc_number' type='text' placeholder='0123456789' ref={accNumberRef}
						onChange={() => setAccNumberWarning(null)}
					/>
					<p className={accNumberWarning && styles.warning}>{accNumberWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='acc_holder'>Account holder</label>
					<input id='acc_holder' type='text' placeholder='NGUYEN VAN A' ref={accHolderRef}
						onChange={() => setAccHolderWarning(null)}
					/>
					<p className={accHolderWarning && styles.warning}>{accHolderWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='qr_img_url'>QR image url</label>
					<input id='qr_img_url' type='text' placeholder='https://qr_img_url.com' ref={qrImgUrlRef}
						onChange={() => setQrImgUrlWarning(null)}
					/>
					<p className={qrImgUrlWarning && styles.warning}>{qrImgUrlWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='acc_number'>Bank image url</label>
					<input id='acc_number' type='text' placeholder='https://bank_img_url.com' ref={bankImgUrlRef}
						onChange={() => setBankImgUrlWarning(null)}
					/>
					<p className={bankImgUrlWarning && styles.warning}>{bankImgUrlWarning}</p>
				</div>
				<button type="submit" className={clsx(styles.submit, 'btn active')}>
					{editingPayment ? 'Update' : 'Create'}
				</button>
			</form>
			<div className={clsx(styles.data)}>
				<h2><Link to={routes.payment}>All Payments</Link></h2>
				<div className={clsx(styles.tableWrapper)}>
					<table>
						<thead>
							<tr>
								<th>Bank name</th>
								<th>Account number</th>
								<th>Account holder</th>
								<th>QR image url</th>
								<th>Bank image url</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{payments && payments.map(payment => (
								<tr key={payment.id}>
									<td>{payment.bank_name}</td>
									<td>{payment.acc_number}</td>
									<td>{payment.acc_holder}</td>
									<td className={clsx(styles.writingTags)}>
										<Link to={payment.qr_img_url}>{payment.qr_img_url}</Link>
									</td>
									<td className={clsx(styles.writingTags)}>
										<Link to={payment.bank_img_url}>{payment.bank_img_url}</Link>
									</td>
									<td className={clsx(styles.action)}>
										<Link onClick={() => handleEdit(payment)}><FontAwesomeIcon icon={faPen}/></Link>
										<Link onClick={() => handleDelete(payment.id)}><FontAwesomeIcon icon={faTrash}/></Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export { Payment }