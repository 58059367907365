import clsx from 'clsx'
import styles from './Admin.module.scss'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Login from './Login'
import useToken from './useToken'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getQuotes, updateQuotes } from '~/services/quotes'
import { Dialog, Toast } from '~/utils/Noti'

function Quotes() {
    document.title = titles.adminQuotes
	useEffect(() => window.scrollTo(0, 0), [])

	const { token, setToken } = useToken()
	const [quotes, setQuotes] = useState([])
	const [editingQuote, setEditingQuote] = useState(null)
	const [contentWarning, setContentWarning] = useState(null)
	const [sourceWarning, setSourceWarning] = useState(null)
	const contentRef = useRef(null)
	const sourceRef = useRef(null)
	const mounted = useRef(true)

	useEffect(() => {
		mounted.current = true
		getQuotes()
		.then(items => {
			if(mounted.current) setQuotes(items.data)
		})
		return () => mounted.current = false
	}, [])

	const handleSubmit = async e => {
		e.preventDefault()
		if (contentRef.current.value.trim() === '') {
			setContentWarning('Content can\'t be blank.')
			return
		}
		if (sourceRef.current.value.trim() === '') {
			setSourceWarning('Source can\'t be blank.')
			return
		}
		const newQuote = {
			content: contentRef.current.value.trim(),
			source: sourceRef.current.value.trim()
		}
		if (editingQuote) {
			const response = await updateQuotes({ ...newQuote, id: editingQuote.id }, 'PUT')
			if (response.code === '10') {
				Toast('success', 'Update quote successfully.', newQuote.name)
				setEditingQuote(null)
			}
		} else {
			const response = await updateQuotes(newQuote, 'POST')
			if (response.code === '8') {
				Toast('success', 'Create new quote successfully.', newQuote.name)
			}
		}
		if (mounted.current) {
			contentRef.current.value = ''
			sourceRef.current.value = ''
		}
		getQuotes().then(items => setQuotes(items.data))
	}

	const handleEdit = quote => {
		setEditingQuote(quote)
		contentRef.current.value = quote.content
		sourceRef.current.value = quote.source
		window.scrollTo(0, 0)
	}

	const handleDelete = async quoteId => {
		const resConfirmed = await Dialog('Confirm deletion', 'Are you sure you want to delete this quote?')
		if (resConfirmed) {
			const response = await updateQuotes({id: quoteId}, 'DELETE')
			if (response.code === '7') {
				Toast('success', 'Delete quote successfully.')
				getQuotes().then(items => setQuotes(items.data))
			}
		}
	}
	
	if (!token) return <Login setToken={setToken}/>
	return (
		<div className={clsx(styles.wrapper, 'container')}>
			<h1>Quotes</h1>
			<form className={clsx(styles.form)} onSubmit={handleSubmit}>
				<div className={clsx(styles.field)}>
					<label htmlFor='content'>Content</label>
					<input id='content' type='text' placeholder='Attitude is a little thing that makes a big difference.' ref={contentRef}
						onChange={() => setContentWarning(null)}
					/>
					<p className={contentWarning && styles.warning}>{contentWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='source'>Source</label>
					<input id='source' type='text' placeholder='Winston S. Churchill' ref={sourceRef}
						onChange={() => setSourceWarning(null)}
					/>
					<p className={sourceWarning && styles.warning}>{sourceWarning}</p>
				</div>
				<button type="submit" className={clsx(styles.submit, 'btn active')}>
					{editingQuote ? 'Update' : 'Create'}
				</button>
			</form>
			<div className={clsx(styles.data)}>
				<h2><Link to={routes.quotes}>All Quotes</Link></h2>
				<div className={clsx(styles.tableWrapper)}>
					<table>
						<thead>
							<tr>
								<th>Content</th>
								<th>Source</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{quotes && quotes.map(quote => (
								<tr key={quote.id}>
									<td>{quote.content}</td>
									<td>{quote.source}</td>
									<td className={clsx(styles.action)}>
										<Link onClick={() => handleEdit(quote)}><FontAwesomeIcon icon={faPen}/></Link>
										<Link onClick={() => handleDelete(quote.id)}><FontAwesomeIcon icon={faTrash}/></Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export { Quotes }