import clsx from 'clsx'
import styles from './NotFound.module.scss'
import titles from '~/config/titles'

function NotFound() {
    document.title = titles.notFound

    return (
        <div className={clsx(styles.wrapper)}>
            <h1>OOPS!</h1>
            <p>Looks like we've lost you somewhere.</p>
        </div>
    )
}

export default NotFound