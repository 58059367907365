import clsx from 'clsx'
import styles from './Tags.module.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { getTags } from '~/services/tags'

function Tags() {
    document.title = titles.tags
    useEffect(() => window.scrollTo(0, 0), [])

    const [tags, setTags] = useState([])

	useEffect(() => {
		let mounted = true
		getTags()
		.then(items => {
			if(mounted) setTags(items.data)
		})
		return () => mounted = false
	}, [])

    const tagsByFirstLetter = new Map()
    for (const tag of tags) {
        const firstLetter = tag.name.charAt(0).toUpperCase()
        if (!tagsByFirstLetter.has(firstLetter)) {
            tagsByFirstLetter.set(firstLetter, [])
        }
        tagsByFirstLetter.get(firstLetter).push(tag)
    }
    for (const [_, tagGroup] of tagsByFirstLetter) {
        tagGroup.sort((a, b) => a.name.localeCompare(b.name))
    }
    const result = Array.from(tagsByFirstLetter.values())

    return (
        <div className={clsx(styles.wrapper, 'container')}>
            <section className='pageHead'>
                <h1>Tags</h1>
            </section>
            <section className={clsx(styles.tagsWrapper)}>
                {result && result.map((tagAlphabets, index) => (
                    <div className={clsx(styles.tagsListAlphabet)} key={index}>
                        <span>{tagAlphabets[0].name.charAt(0).toUpperCase()}</span>
                        <div className={clsx(styles.tagsList)}>
                            {tagAlphabets.map(tag => (
                                <Link key={tag.id} to={`${routes.tags}/${tag.name}`} className={clsx(styles.tagsItem, 'btn sm active')}>{tag.name}</Link>
                            ))}
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export { Tags }