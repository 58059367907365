import clsx from 'clsx'
import styles from './Admin.module.scss'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Login from './Login'
import useToken from './useToken'
import titles from '~/config/titles'
import routes from '~/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getTags, updateTags } from '~/services/tags'
import { Dialog, Toast } from '~/utils/Noti'

function Tags() {
    document.title = titles.adminTags
	useEffect(() => window.scrollTo(0, 0), [])

	const { token, setToken } = useToken()
	const [tags, setTags] = useState([])
	const [editingTag, setEditingTag] = useState(null)
	const [nameWarning, setNameWarning] = useState(null)
	const nameRef = useRef(null)
	const descriptionRef = useRef(null)
	const mounted = useRef(true)

	useEffect(() => {
		mounted.current = true
		getTags()
		.then(items => {
			if(mounted.current) setTags(items.data)
		})
		return () => mounted.current = false
	}, [])

	const handleSubmit = async e => {
		e.preventDefault()
		if (nameRef.current.value.trim() === '') {
			setNameWarning('Name can\'t be blank.')
			return
		}
		const newTag = {
			name: nameRef.current.value.trim(),
			description: descriptionRef.current.value.trim()
		}
		if (editingTag) {
			const response = await updateTags({ ...newTag, id: editingTag.id }, 'PUT')
			if (response.code === '10') {
				Toast('success', 'Update tag successfully.', newTag.name)
				setEditingTag(null)
			}
		} else {
			const response = await updateTags(newTag, 'POST')
			if (response.code === '8') {
				Toast('success', 'Create new tag successfully.', newTag.name)
			}
		}
		if (mounted.current) {
			nameRef.current.value = ''
			descriptionRef.current.value = ''
		}
		getTags().then(items => setTags(items.data))
	}

	const handleEdit = tag => {
		setEditingTag(tag)
		nameRef.current.value = tag.name
		descriptionRef.current.value = tag.description
		window.scrollTo(0, 0)
	}

	const handleDelete = async tagId => {
		const resConfirmed = await Dialog('Confirm deletion', 'Are you sure you want to delete this tag?')
		if (resConfirmed) {
			const response = await updateTags({id: tagId}, 'DELETE')
			if (response.code === '7') {
				Toast('success', 'Delete tag successfully.')
				getTags().then(items => setTags(items.data))
			}
		}
	}
	
	if (!token) return <Login setToken={setToken}/>
	return (
		<div className={clsx(styles.wrapper, 'container')}>
			<h1>Tags</h1>
			<form className={clsx(styles.form)} onSubmit={handleSubmit}>
				<div className={clsx(styles.field)}>
					<label htmlFor='name'>Name</label>
					<input id='name' type='text' placeholder='api' ref={nameRef}
						onChange={() => setNameWarning(null)}
					/>
					<p className={nameWarning && styles.warning}>{nameWarning}</p>
				</div>
				<div className={clsx(styles.field)}>
					<label htmlFor='description'>Description</label>
					<input id='description' type='text' placeholder='Application Programming Interface' ref={descriptionRef}/>
					<p>Some additional Info</p>
				</div>
				<button type="submit" className={clsx(styles.submit, 'btn active')}>
					{editingTag ? 'Update' : 'Create'}
				</button>
			</form>
			<div className={clsx(styles.data)}>
				<h2><Link to={routes.tags}>All Tags</Link></h2>
				<div className={clsx(styles.tableWrapper)}>
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Description</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{tags && tags.map(tag => (
								<tr key={tag.id}>
									<td className={clsx(styles.writingTags)}>
										<Link to={`${routes.tags}/${tag.name}`}>{tag.name}</Link>
									</td>
									<td>{tag.description === '' ? '-' : tag.description}</td>
									<td className={clsx(styles.action)}>
										<Link onClick={() => handleEdit(tag)}><FontAwesomeIcon icon={faPen}/></Link>
										<Link onClick={() => handleDelete(tag.id)}><FontAwesomeIcon icon={faTrash}/></Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export { Tags }